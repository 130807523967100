<template>
  <v-row>
    <v-col cols="12" md="3">
      <SelectMultipleWarehouse
        :is-check-active="isCheckActive"
        :key="`w_${isCheckActive}`"
        :disabled="inputs.id_poss.length > 0"
        :label="$t('labels.warehouse')"
        :placeholder="$t('labels.warehouse')"
        name="id_warehouses"
        @onFilter="onFilterChange"
      />
    </v-col>
    <v-col cols="12" md="3">
      <SelectMultiplePos
        :is-check-active="isCheckActive"
        :key="`p_${isCheckActive}`"
        :disabled="inputs.id_warehouses.length > 0"
        :label="$t('labels.pos')"
        :placeholder="$t('labels.pos')"
        name="id_poss"
        @onFilter="onFilterChange"
      />
    </v-col>
    <v-col cols="12" md="3">
      <SelectFilter
        :options="objects"
        :label="$t('labels.object')"
        :key="`o_${isCheckActive}`"
        :placeholder="$t('labels.object')"
        name="object"
        @onFilter="onFilterChange"
      />
    </v-col>
    <template v-if="inputs.id_warehouses.length > 0">
      <v-col cols="12" md="3" v-if="inputs.object === 1">
        <SelectMultipleEmployeeByWarehouse
          :is-check-active="isCheckActive"
          :label="$t('labels.employee')"
          :placeholder="$t('labels.employee')"
          name="id_employees"
          :id-warehouses="inputs.id_warehouses"
          :key="`e_${isCheckActive}`"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col cols="12" md="3" v-if="inputs.object === 2">
        <SelectMultipleCustomerByWarehouse
          :is-active-only="isCheckActive"
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          name="id_customers"
          :id-warehouses="inputs.id_warehouses"
          :key="`c_${isCheckActive}`"
          @onFilter="onFilterChange"
        />
      </v-col>
    </template>
    <template v-if="inputs.id_poss.length > 0">
      <v-col cols="12" md="3" v-if="inputs.object === 1">
        <SelectMultipleEmployeeByPos
          :is-check-active="isCheckActive"
          :label="$t('labels.employee')"
          :placeholder="$t('labels.employee')"
          name="id_employees"
          :id-poss="inputs.id_poss"
          :key="`ep_${isCheckActive}`"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col cols="12" md="3" v-if="inputs.object === 2">
        <SelectMultipleCustomerByPos
          :is-active-only="isCheckActive"
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          name="id_customers"
          :id-poss="inputs.id_poss"
          :key="`cp_${isCheckActive}`"
          @onFilter="onFilterChange"
        />
      </v-col>
    </template>

    <v-col cols="12" md="12">
      <v-text-field
        v-model="inputs.title"
        dense
        outlined
        clearable
        hide-details
        maxlength="255"
        :label="$t('labels.title')"
        class="c-input-small"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12">
      <v-textarea
        v-model="inputs.content"
        dense
        outlined
        clearable
        hide-details
        maxlength="5000"
        :label="$t('labels.content')"
        class="c-input-small"
      ></v-textarea>
    </v-col>
    <v-col cols="12" md="12">
      <v-btn
        block
        color="default"
        outlined
        @click="$refs.inputUploadFile.click()"
      >
        <img src="@/assets/common/upload.png" style="height: 24px" alt="" />
        {{ $t("labels.image") }}
        <span v-if="inputs.images.length">({{ inputs.images.length }})</span>
      </v-btn>
      <input
        type="file"
        ref="inputUploadFile"
        multiple
        accept="image/*"
        @change="onInputFileChange"
        class="d-none"
      />
    </v-col>
    <v-col cols="12" v-if="inputs.images && inputs.images.length > 0">
      <v-avatar
        class="ma-1"
        size="50px"
        v-for="(img, key) in getNotifyImages(inputs.images)"
        :key="`notify_image_${key}`"
        style="border: 1px solid #9e9e9e; border-radius: 5px"
        @click="showImages(inputs.images, key)"
      >
        <img :src="img" />
      </v-avatar>
    </v-col>
    <v-col cols="12" md="12">
      <div class="text-right">
        <v-btn
          color="primary"
          @click="pushNotification"
          :disabled="isDisabledBtn"
          ><v-icon>mdi-send</v-icon></v-btn
        >
      </div>
    </v-col>
    <v-col cols="12" md="12">
      <NotificationList />
    </v-col>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";
import { api as viewerApi } from "v-viewer";

export default {
  name: "Index",
  components: {
    SelectMultipleWarehouse: () =>
      import("@/components/administration/SelectMultipleWarehouse"),
    SelectMultipleEmployeeByWarehouse: () =>
      import("@/components/administration/SelectMultipleEmployeeByWarehouse"),
    SelectMultipleCustomerByWarehouse: () =>
      import("@/components/administration/SelectMultipleCustomerByWarehouse"),
    SelectMultiplePos: () =>
      import("@/components/administration/SelectMultiplePos"),
    SelectMultipleEmployeeByPos: () =>
      import("@/components/administration/SelectMultipleEmployeeByPos"),
    SelectMultipleCustomerByPos: () =>
      import("@/components/administration/SelectMultipleCustomerByPos"),
    NotificationList: () => import("@/components/notification/List"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  data: () => ({
    inputs: {
      id_warehouses: [],
      id_poss: [],
      id_employees: [],
      id_customers: [],
      object: null,
      title: null,
      content: null,
      images: [],
    },
    types: [],
    objects: [],
    isLoading: false,
    isCheckActive: true,
  }),
  created() {
    const objects = [
      {
        text: this.$t("labels.employee"),
        value: 1,
      },
      {
        text: this.$t("labels.customer"),
        value: 2,
      },
    ];
    this.objects = [...objects];
  },
  computed: {
    isDisabledBtn() {
      return (
        ((!this.inputs.id_warehouses ||
          this.inputs.id_warehouses.length === 0) &&
          (!this.inputs.id_poss || this.inputs.id_poss.length === 0)) ||
        ((!this.inputs.id_employees || this.inputs.id_employees.length === 0) &&
          (!this.inputs.id_customers ||
            this.inputs.id_customers.length === 0)) ||
        !this.inputs.title ||
        !this.inputs.content
      );
    },
  },
  methods: {
    getNotifyImages(images) {
      if (images && images.length > 0) {
        // images = images.split(';')
        return images.map(
          (img) => `${process.env.VUE_APP_FILE_CDN_URL}/${img}`
        );
      }
      return [];
    },
    showImages(images, index) {
      if (!images || images.length === 0) {
        return false;
      }
      index = index || 0;
      images = this.getNotifyImages(images);
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images,
      });
    },

    onFilterChange(filter) {
      this.inputs = { ...this.inputs, [filter.name]: filter.value };
    },

    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      let urls = [];
      for (let i = 0; i < files.length; i++) {
        const url = await this.uploadFile(files[i]);
        urls.push(url);
      }
      this.inputs = { ...this.inputs, images: urls };
      this.$refs.inputUploadFile.value = null;
      this.isLoading = false;
    },

    async uploadFile(file) {
      let fd = new FormData();
      fd.append("file", file);
      const { data } = await httpClient.post(`/common/v1/upload-image`, fd);
      return data;
    },

    async pushNotification() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/notification/v1/create", this.inputs);
        this.isLoading = false;
        const inputs = {
          title: null,
          content: null,
          images: [],
        };
        this.inputs = { ...this.inputs, ...inputs };
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
